import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import AuthContext from "contexts/AuthContext";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

const SsoLoginModal = ({ programId }) => {
  const iframeRef = useRef(null);
  const { setAuth } = useContext(AuthContext);
  const history = useHistory();
  const [iframeHeight, setIframeHeight] = useState(407);

  useEffect(() => {
    const handleMessage = event => {
      if (event.data) {
        if (Object.hasOwn(event.data, "hasGoogle") && Object.hasOwn(event.data, "hasFacebook")) {
          const frameHeight =
            event.data?.hasGoogle && event.data?.hasFacebook
              ? 535
              : event.data?.hasGoogle || event.data?.hasFacebook
                ? 500
                : 407;

          setIframeHeight(frameHeight);
        }
      }

      if (event.origin !== process.env.REACT_APP_BASE_PATH) return; // Update with your backend app URL

      if (event.data && event.data.token) {
        const tokenData = JSON.parse(event.data.token);
        const userData = JSON.parse(event.data.user);

        setAuth(userData, `Bearer ${tokenData?.value}`);
        history.push("/");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const style = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <React.Fragment>
      <Grid px={5} mt={1} spacing={2} container justifyContent={{ xs: "center", md: "start" }}>
        <Box sx={style}>
          <iframe
            ref={iframeRef}
            title="SSO Login Frame"
            src={`${process.env.REACT_APP_SSO_LOGIN_PATH}?program=${programId}`}
            style={{ height: iframeHeight, width: "100%", border: "none" }}
          ></iframe>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default SsoLoginModal;
